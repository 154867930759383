<template>
  <div
    class="row items-center full-width main-btn no-wrap"
    :class="[
      { reverse: isReverse, padding: hasPadding },
      isInverse ? 'inverse' : 'section-button-image',
      getStyleButton(styleButton),
    ]"
  >
    <!-- <q-btn
      unelevated
      :dense="isDense"
      rounded
      :label="titleButton"
      class="button-image"
      :to="{ name: route, query: query }"
    /> -->
    <router-link
      v-if="route != ''"
      class="link-content"
      :to="{ name: route, query: query }"
    >
      {{ titleButton }}
    </router-link>
    <label class="link-content" @click="onClickBtn()" v-else>
      {{ titleButton }}
    </label>
    <div class="row items-center btn-content">
      <q-img
        v-if="isShowIcon"
        :src="require(`@/assets/icons/${icon}.svg`)"
        class="button-icon q-mx-sm"
        color="white"
        width="23px"
        height="16px"
        style="filter: brightness(0) invert(1)"
      />
      <q-btn
        v-if="subtitle"
        :dense="isDense"
        unelevated
        rounded
        :label="subtitle"
        class="button-subtitle full-height"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "RoundedBtn",
  emits: ["click"],
  props: {
    titleButton: String,
    subtitle: String,
    isInverse: {
      type: Boolean,
      default: false,
    },
    hasPadding: {
      type: Boolean,
      default: true,
    },
    isDense: {
      type: Boolean,
      default: false,
    },
    isReverse: {
      type: Boolean,
      default: false,
    },
    isShowIcon: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "arrow-right-bar-right",
    },
    route: {
      type: String,
      default: "",
    },
    query: {
      type: Object,
      default: {},
    },
    styleButton: {
      type: String,
      default: "default",
    },
    onClickBtn: {
      type: Function,
    },
  },
  setup() {
    function getStyleButton(name) {
      switch (name) {
        case "create-exam":
          return "button-exam";
        case "green":
          return "button-green";
        case "pink-inverse":
          return "button-pink-inverse";
        case "grey-inverse":
          return "button-grey-inverse";
        case "orange":
          return "button-orange";
        case "default-inverse":
          return "button-default-inverse";
        default:
          return "button-default";
      }
    }

    return {
      getStyleButton,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-btn {
  min-width: 130px;
  box-shadow: 3px 6px 15px rgba(0, 0, 0, 0.15);
}

.reverse {
  flex-direction: row-reverse;
}

.padding {
  padding: 5px;
}

.link-content {
  text-decoration: none;
  background: #ffffff;
  padding: 5% 8%;
  border-radius: 20px;

  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  cursor: pointer;
  /* color: var(--q-primary, #fe4e64);*/
}

.inverse {
  background-color: #ffffff;
  border-radius: 20px;
  margin: 0 0 1% 1%;
  height: 100%;

  .button-image {
    background: none;
    color: var(--q-primary, #fe4e64);
  }

  .button-icon {
    width: 23px;
    /* margin-left: 9px; */
    height: 16px;
  }

  .button-subtitle {
    background-color: var(--q-primary, #fe4e64);
    font-style: normal;
    font-weight: normal;
    font-size: 0.87rem;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #fdfdfd;
  }
}

.btn-content {
  height: 100%;
}

.section-button-image {
  display: flex;
  flex-direction: row;
  background-color: var(--q-primary, #fe4e64);
  border-radius: 20px;
  /* padding: 5px; */
  margin: 0 0 1% 1%;
  height: 100%;

  .button-image {
    background-color: #ffffff;
    box-shadow: 1.5px 1.5px 4px rgba(0, 0, 0, 0.15);
    color: var(--q-primary, #fe4e64);
  }
  .button-icon {
    width: 23px;
    /* margin-left: 9px; */
    height: 16px;
  }

  .button-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 0.87rem;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #fdfdfd;
  }
}

.button-default {
  background-color: var(--q-secundary);
  // var(--q-primary, #fe4e64)

  .button-image {
    background-color: #ffffff;
    color: var(--q-secundary);
  }
  .link-content {
    color: var(--q-secundary);
  }
  .button-subtitle {
    color: #fdfdfd;
  }

  .inverse {
    background-color: #ffffff;

    .button-image {
      background: none;
      color: var(--q-primary, #fe4e64);
    }

    .button-subtitle {
      background-color: var(--q-primary, #fe4e64);
      color: #fdfdfd;
    }
  }
}

.button-orange {
  background-color: #fa6b1f;

  .button-image {
    background-color: #ffffff;
    color: #fa6b1f;
  }
  .link-content {
    color: #fa6b1f;
  }
  .button-subtitle {
    color: #fdfdfd;
  }

  .inverse {
    background-color: #ffffff;

    .button-image {
      background: none;
      color: var(--q-primary, #fe4e64);
    }

    .button-subtitle {
      background-color: var(--q-primary, #fe4e64);
      color: #fdfdfd;
    }
  }
}

.button-green {
  background-color: #7cae44;

  .button-image {
    background-color: #ffffff;
    color: #7cae44;
  }
  .link-content {
    color: #7cae44;
  }
  .button-subtitle {
    color: #fdfdfd;
  }

  .inverse {
    background-color: #ffffff;

    .button-image {
      background: none;
      color: var(--q-primary, #fe4e64);
    }

    .button-subtitle {
      background-color: var(--q-primary, #fe4e64);
      color: #fdfdfd;
    }
  }
}

.button-exam {
  background-color: #489b88;

  .button-image {
    background-color: #ffffff;
    color: #489b88;
  }
  .link-content {
    color: #489b88;
  }
  .button-subtitle {
    color: #fdfdfd;
  }

  .inverse {
    background-color: #ffffff;

    .button-image {
      background: none;
      color: var(--q-primary, #fe4e64);
    }

    .button-subtitle {
      background-color: var(--q-primary, #fe4e64);
      color: #fdfdfd;
    }
  }
}

.button-default-inverse {
  background-color: #ffffff;

  .button-image {
    background: none;
    color: var(--q-primary, #fe4e64);
  }

  .button-subtitle {
    background-color: var(--q-primary, #fe4e64);
    color: #fdfdfd;
  }
}

.button-grey-inverse {
  background-color: #e5e5e5;

  .button-image {
    background: none;
    color: #9c9c9c;
  }

  .button-subtitle {
    background-color: #ffffff;
    color: #9c9c9c;
  }
}

.button-pink-inverse {
  background-color: #ffffff;

  .button-image {
    background: none;
    color: var(--q-primary, #fe4e64);
  }

  .button-subtitle {
    background-color: #ffe7ea;
    color: var(--q-primary, #fe4e64);
  }
}
</style>
