<template>
  <div class="flex column justify-center trilha-card"  :class="[getColorBgBadge('file')]">
    <div class="card-info-trilha">
      <div class="col-12 section-image-trilha">
       <div class="video-form">
        <vue3-video-player  :src="image" @play="$emit('publish')" 
        >
        </vue3-video-player>
       </div>

      </div>
      <div class="header col-12 q-pt-md">
        <div class="avatar-notification q-mx-md">
          <BadgeType
            typeBadge="file"
            iconBadge="video"
          />
        </div>
        <div class="title-Info">
          <h6>
            {{ title }}
          </h6>
          <small>{{ dateFormart }}</small>
        </div>
      </div>
      <div class="col-12">
        <p class="description">
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>


<script>
//----IMAGES
import mediaImg from '@/assets/icons/media_video.svg';

import BadgeType from "@/components/BadgeType.vue";
import ColorsClass from "@/utils/styles/colors/getColors.js";

import { date } from 'quasar';

export default {
  name: "TutorNotificationCard",
  components:{
    BadgeType
  },
  props: {
    title: String,
    date_created: String,
    description: String,
    image: String,
  },
  emits:["publish"],
  setup(props){
    const Colors = new ColorsClass();

    let dateFormart = date.formatDate(props.date_created, 'DD/MM/YYYY H:mm');
    
    function getColorBgBadge(name) {
      return Colors.getColorBgBadge(name);
    }

     return {
      //----IMAGES
      mediaImg,

      dateFormart,

      getColorBgBadge
     };
  }
};
</script>

<style lang="scss" scoped>
.trilha-card {
  width: 100%;
  height: 100%;

  background: #fdfdfd;
  border-radius: 20px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
  0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
  box-shadow: rgb(0 0 0 / 10%) 3px 6px 15px;

}

.header {
  display: flex;
  flex-direction: row;
  position: relative;

  &::before {
    content: "";
    width: 3px;
    height: 35px;
    position: absolute;
    border-radius: 0px 2px 2px 0px;
  }

  .avatar-notification{
    width: 45px;
    height: 45px;
  }

  .title-Info {
    width: 80%;

    h6 {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 17px;
    }

    span {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 17px;
    }

    small {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #0000004d;
    }
  }
}

.section-image-trilha {
  width: 100%;
  height: 100%;
  max-height: 280px;
  padding: 4px;
  .video-form{
    height: 100%;
    overflow: hidden;
    border-radius: 20px;
  }
  .image-trilha {
    border-radius: 10px;
  }

  .section-button-image {
    background-color: var(--q-primary, #fe4e64);
    border-radius: 20px;
    padding: 3px !important;
    margin: 0 0 1% 1%;

    .button-image {
      background-color: #ffffff;
      color: #FE5268;
    }
  }
}

.description {
  padding: 0 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 16px;
  color: #0009;
}

.item-bg-yellow {
  .header::before {
    background-color: #d49a41;
  }
}
.item-bg-purple {
  .header::before {
    background-color: #a55ab3;
  }
}
.item-bg-blue {
  .header::before {
    background-color: #3f8ab3;
  }
}
.item-bg-green {
  .header::before {
    background-color: #77aa3d;
  }
}
.item-bg-depp-green {
  .header::before {
    background-color: #439380;
  }
}
</style>